import HttpClient from '@/plugins/httpClient'
import GraphQlClient from "@/plugins/graphqlClient"
import { ApiGateway } from '@/plugins/gateway'

var analysisClient = new HttpClient(`${ApiGateway.Emr}/Analysis`);
var flowClient = new HttpClient(`${ApiGateway.Emr}/Flow`);
var graphQlClient = new GraphQlClient(ApiGateway.AiFunctions)

export async function getAnalysisDetail(analysisID) {
  return analysisClient.get(`/Get/${analysisID}`);
}

export async function getTaskDetail(taskID) {
  var query = `
  query {
    getTaskDetail(taskID: "${taskID}") {
      taskID
      jsonResults
      jsonSchema
      percent
      statusID
    }
  }`
  let res = await graphQlClient.post(query)
  if (res.error) return res
  return res.getTaskDetail
}

export async function getAllFlowBlocks(page = 1, limit = 10000) {
  var query = `
  query {
    searchFunctions(page: ${page}, limit: ${limit}) {
      name
      originalName
      title
      family
      description
      fields
      allowAddFields
    }
  }`
  let res = await graphQlClient.post(query)
  if (res.error) return res
  return res.searchFunctions
}

export async function getFlowDetail(flowID) {
  return await flowClient.get(`/Get/${flowID}`)
}

export async function getFlowDetailByTitle(title) {
  var query = { title }
  return await flowClient.get(`/GetByTitle`, query)
}

export async function searchFlows(keySearch = "", page = 1, limit = 10) {
  return await flowClient.get('/Search', { keySearch }, { page, limit });
}

export async function deleteFlow(flowID) {
  return await flowClient.put(`/Delete/${flowID}`);
}

export async function createFlow(data) {
  return await flowClient.post('/Create', {}, data);
}

export async function updateFlow(flowID, data) {
  return await flowClient.put(`/Update/${flowID}`, {}, data);
}

